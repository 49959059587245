import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BASE_URL } from '../../constant/constants';
import { getAlladvisers } from '../../dataSlice/dataSlice';

const Advisor = (props) => {
  const dispatch = useDispatch();
  const advisers = useSelector((state) => state.data.advisers);
  // GET_ADVISERS_WITHOUT_PG
  useEffect(() => {
    dispatch(getAlladvisers());
  }, [dispatch, props.id]);

  return (
    <section
      className={`${props.Fclass} section-padding  ${props.vclassClass}`}>
      <div>
        {advisers?.length > 0 ? (
          <div>
            <div className='row container mx-auto'>
              {advisers?.map((adviser, index) => (
                <div className='col-md-6 p-3 px-5 ' key={index}>
                  <div
                    className='row shadow'
                    style={{
                      backgroundColor: '#e6f1e0',
                      borderTopLeftRadius: '30px',
                      borderBottomRightRadius: '30px',
                      border: '2px solid #d7d7d7',
                    }}>
                    <div className='col-md-3 my-auto '>
                      <img
                        src={`${BASE_URL}${adviser?.image}`}
                        className='rounded-left  border  border-dark'
                        width='100'
                        style={{
                          borderTopRightRadius: '30px',
                          borderBottomLeftRadius: '30px',
                        }}
                        loading='lazy'
                        height='100'
                        alt='...'
                      />
                    </div>
                    <div
                      className='col-md-9 mt-3 '
                      style={{ paddingRight: '0px' }}>
                      <div
                        style={{
                          backgroundColor: '#172847',
                          borderLeft: '3px solid white',
                          borderTop: '3px solid white',
                          borderBottom: '3px solid white',
                        }}>
                        <h4 className='text-white p-1'>{adviser?.name}</h4>
                        <h5 className='pb-1 px-2' style={{ color: '#2d9436' }}>
                          {adviser?.designation}
                        </h5>
                      </div>
                      <small> {adviser?.designation_agency}</small>
                      <h4> {adviser?.agency}</h4>
                      <small>
                        <b>RL- {adviser?.rl_no}</b>
                        <br />
                      </small>
                      <small> {adviser?.address}</small>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <h2 className='text-center'> No Adviser found</h2>
          </div>
        )}
      </div>
    </section>
  );
};

export default Advisor;
