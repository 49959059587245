import React, { Fragment, useEffect, useState } from 'react';
import Hero from '../../components/hero';

import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import AdvisorMemberSection from '../../components/AdvisorMemberSection';
import AboutS2 from '../../components/AboutS2';
import OurLivePrograms from '../../components/OurLivePrograms';
import { GET_MENUS_ALL_NESTED } from '../../constant/constants';
import ECMemberSection from '../../components/ECMemberSection';

const HomePage = () => {
  const [homeId, setHomeId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === 'Home' ? setHomeId(e.id) : null));
      })
      .catch(() => {});
  }, [homeId]);

  return (
    <Fragment>
      <Hero />
      {/* <OurCompanies id={homeId} tClass={"wpo-testimonial-area-s3 "} /> */}
      <OurLivePrograms tClass={'wpo-testimonial-area-s3 '} />

      <AboutS2 id={homeId} />
      {/* <OurPreviousPrograms tClass={'wpo-testimonial-area-s3 '} /> */}
      {/* <Service5 id={serviceId} /> */}
      {/* <ProjectSection4 id={homeId} /> */}
      <ECMemberSection />
      <AdvisorMemberSection />
      {/* <OurBlogs id={homeId} tClass={'wpo-testimonial-area-s3 '} /> */}

      <Scrollbar />
      <Footer />
    </Fragment>
  );
};
export default HomePage;
