import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap-v5';

import { BASE_URL, GET_SLIDERSETTINGS } from '../../constant/constants';

const Hero = () => {
  const [contents, setContents] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //for content
  useEffect(() => {
    fetch(`${GET_SLIDERSETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        // setContents(data.homepage_sliders);
        console.log('sliderImages', data.homepage_sliders);
        setContents(data.homepage_sliders);
        // data.homepage_sliders.map((n) => setContents(n.image));
      })
      .catch(() => {});
  }, []);

  return (
    <Carousel fade>
      {contents?.map((n, item) => (
        <Carousel.Item
          key={item}
          style={{
            backgroundImage: `url(${BASE_URL}${n?.image})`,
            backgroundSize: 'cover',
            width: '100%',
            backgroundPosition: 'center',
            height: windowWidth < 600 ? '300px' : '100vh',
          }}>
          {/* You can optionally add content inside each Carousel.Item */}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Hero;
