import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { BASE_URL, GET_MEMBER_BY_ID } from "../../constant/constants";

import "./style.scss";

const ProfilePage = (props) => {
  const [user, setUser] = useState({});

  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    fetch(`${GET_MEMBER_BY_ID}${user_id}`)
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
      })
      .catch(() => {});
  }, [user_id]);

  return (
    <Grid className="profileWrapper">
      <div class="container mt-5 ">
        <div class="row d-flex justify-content-center mt-5">
          <div
            class="col-md-7 shadow-lg p-3 mb-5 bg-white rounded"
            style={{ marginTop: "70px" }}
          >
            <div class="card p-3 py-4">
              <div class="text-center">
                <img
                  src={`${BASE_URL}${user?.image}`}
                  alt=""
                  width="100"
                  class="rounded-circle"
                />
              </div>

              <div class="text-center mt-3">
                <span class="bg-secondary p-1 px-4 rounded text-white">
                  {user?.user_type}
                </span>
                <h5 class="mt-2 mb-0">
                  {user?.first_name} {user?.last_name}
                </h5>
                <span className="text-primary">{user?.profession}</span>

                {/* <div class="px-4 mt-1">
                  <p class="fonts">
                    Consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.{" "}
                  </p>
                </div> */}
                <ul class="social-list">
                  <li className="text-danger">
                    Memership : {user?.created_at?.slice(0, 10)}
                  </li>
                  <li>
                    <i class="fa fa-envelope"> {user?.email}</i>
                  </li>
                  <li>
                    <i class="fa fa-phone"> {user?.primary_phone}</i>
                  </li>
                  <li>
                    <i class="fa fa-map-marker"> {user?.street_address_one}</i>
                  </li>
                </ul>

                {/* <div class="buttons">
                  <button class="btn btn-outline-primary px-4"> Message</button>
                  <button class="btn btn-primary px-4 ms-3"> Contact</button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default withRouter(ProfilePage);
