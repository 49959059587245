/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Interweave } from 'interweave';
import Slider from 'react-slick';
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
  GET_MENUS_ALL_NESTED,
} from '../../constant/constants';

const settings = {
  dots: true,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    { breakpoint: 1500, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    { breakpoint: 1200, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    { breakpoint: 991, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    { breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  ],
};

const items = (obj) => {
  const content = [];

  for (const key in obj) {
    const contentItem = {};
    const objs = obj;

    contentItem[key] = objs[key];
    content.push(contentItem);
  }

  return content;
};

const OurLivePrograms = (props) => {
  const [id, setId] = useState('');
  const [contents, setContents] = useState('');
  const [contentsImage, setContentsImage] = useState('');
  const [subMenu, setSubMenu] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const ClickHandler = useCallback(() => {
    window.scrollTo(10, 0);
  }, []);

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        const programSubMenu =
          data.menus.find((e) => e.name === 'Project')?.children || [];
        setSubMenu(programSubMenu);
      })
      .catch(() => {});
  }, [id]);

  useEffect(() => {
    const liveProgramSubMenu = subMenu.find(
      (element) => element.name === 'Live Project'
    );
    setId(liveProgramSubMenu?.id);
    setProjectList(liveProgramSubMenu?.children || []);
  }, [subMenu]);

  useEffect(() => {
    if (id && props.id) {
      fetch(`${GET_CONTENTS_BY_MENU_ID}/${id}`)
        .then((response) => response.json())
        .then((data) => {
          setContents(data.menu_contents);
          console.log('allmenucontent', data);
        })
        .catch(() => {});

      fetch(`${GET_IMAGE_BY_MENU_ID}/${id}`)
        .then((response) => response.json())
        .then((data) => {
          setContentsImage(data.content_images);
          console.log('imagesContent', data);
        })
        .catch(() => {});
    }
  }, [id, props.id]);

  const newContents = items(contents);

  return (
    <section className='wpo-features-section-s6 section-padding pt-3 '>
      {projectList?.length > 0 && (
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='wpo-section-title mb-2'>
                <span>our</span>
                <h2 className='my-0'>Live Project</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <Slider {...settings}>
              {projectList?.map((service, index) => (
                <div
                  className='col col-xl-4 col-lg-4 col-sm-6 col-12'
                  key={index}>
                  <div
                    className='wpo-features-item p-0'
                    style={{ borderRadius: '20px' }}>
                    <div className=''>
                      <div className=''>
                        <Link
                          to={`/live Project-single/${service?.id}/${service?.name}`}
                          onClick={ClickHandler}>
                          <img
                            style={{
                              borderTopLeftRadius: '20px',
                              borderTopRightRadius: '20px',
                              display: 'block',
                              width: '100%',
                              height:
                                window.innerWidth < 768 ? '200px' : '500px',
                            }}
                            src={`${BASE_URL}${service?.image} `}
                            alt=''
                          />
                        </Link>
                      </div>
                    </div>

                    <h2 className='py-4 text-center'>
                      <Link
                        onClick={ClickHandler}
                        to={`/live Project-single/${service?.id}/${service?.name}`}>
                        {service?.name}
                      </Link>
                    </h2>
                    {/* <div
                      style={{
                        padding: '20px 40px',
                        minHeight: window.innerWidth < 768 ? '600px' : '400px',
                      }}>
                      <Link
                        to={`/live Project-single/${service?.id}/${service?.name}`}
                        onClick={ClickHandler}>
                        <Interweave
                          allowAttributes
                          allowElements
                          disableLineBreaks={true}
                          content={`${service?.note.slice(
                            0,
                            700
                          )}   ...see more`}
                          className=' text-justify'
                        />
                      </Link>
                    </div> */}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </section>
  );
};

export default OurLivePrograms;
