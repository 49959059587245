import React, { Fragment, useEffect, useState } from 'react';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { GET_MENUS_ALL_NESTED } from '../../constant/constants';
import AboutS2 from '../../components/AboutS2';

const AboutPage = () => {
  const [homeId, setHomeId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === 'Home' ? setHomeId(e.id) : null));
      })
      .catch(() => {});
  }, []);
  console.log('nnnnnnn', homeId);

  return (
    <Fragment>
      {' '}
      <PageTitle pageTitle={'About Us'} />
      <AboutS2 id={homeId} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
