import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import {
  BASE_URL,
  GET_EXECUTIVE_MEMBER_BY_SESSIONS,
} from '../../constant/constants';

var settings = {
  dots: false,
  arrows: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
const ECMemberSection = (props) => {
  const [ecMembers, setEcMembers] = useState([]);

  console.log('ecMembers', ecMembers);

  //for content images
  useEffect(() => {
    fetch(`${GET_EXECUTIVE_MEMBER_BY_SESSIONS}?key=&association=EC`)
      .then((response) => response.json())
      .then((data) => {
        setEcMembers(data.executive_members);
        console.log('executive_members', data.executive_members);
      })
      .catch(() => {});
  }, []);

  console.log(`ecMembers`, ecMembers);
  return (
    <div className='wpo-team-area section-padding pt-1'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='wpo-section-title mb-3'>
              <span>Meet Our</span>
              <h2 className='my-0'>Executive Committee</h2>
            </div>
          </div>
        </div>
        <div className='wpo-team-wrap'>
          <div className='team-slider'>
            <Slider {...settings}>
              {ecMembers.map((adviserMember, tm) => (
                <div
                  className='col col-xl-4 col-lg-6 col-sm-6 col-12  my-3 px-3 '
                  key={tm}
                  style={{
                    height: '450px',
                  }}>
                  <div
                    className=' shadow mb-5 bg-gray rounded'
                    style={{ border: '2px solid rgb(229 226 226)' }}>
                    <div className='img-holder justify-content-center  d-flex position-relative pt-2'>
                      <img
                        src={
                          adviserMember?.member?.image
                            ? `${BASE_URL}${adviserMember?.member?.image}`
                            : '/userColor.png'
                        }
                        alt={adviserMember.first_name}
                        className='mb-3 text-center'
                        width='200'
                        height='200'
                        style={{
                          borderRadius: '100px',
                          border: '6px solid purple',
                        }}
                      />
                    </div>

                    <div
                      className='py-4 pb-0 py-2 text-center'
                      style={{
                        height: '200px',
                      }}>
                      <h4
                        className='text-center'
                        style={{
                          height: '50px',
                          padding: '4px',
                        }}>
                        {adviserMember?.member?.first_name}{' '}
                        {adviserMember?.member?.last_name}
                      </h4>
                      <h6
                        className='text-center p-1'
                        style={{ color: '#319336' }}>
                        {adviserMember?.member?.profession || 'N/A'}
                      </h6>
                      <h5 style={{ height: '60px' }}>
                        {' '}
                        {adviserMember?.member?.agency_baira || ''}
                      </h5>

                      <div
                        className='text-center py-1 mt-2 my-auto text-white mb-4 '
                        style={{
                          backgroundColor: 'rgb(25, 40, 71)',

                          fontSize: '20px',
                          fontWeight: '700',
                          height: '40px',
                        }}>
                        <h5 className='text-white'>
                          {' '}
                          {adviserMember?.designation?.name || ''}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECMemberSection;
