/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BASE_URL, GET_MEMBERS } from '../../constant/constants';

const Member = (props) => {
  const [memberMembers, setMemberMembers] = useState([]);
  const [data, setData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  console.log('memberMembers', memberMembers);

  const { id } = useParams();
  console.log('projecID', id);

  //for content images
  useEffect(() => {
    fetch(`${GET_MEMBERS}?page=${currentPage}&size=${rowsPerPage}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setMemberMembers(data.members);
      })
      .catch(() => {});
  }, [currentPage, id, props.id, rowsPerPage]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    // Perform additional actions when a page is clicked (e.g., fetch data)
  };

  const renderPagination = () => {
    const paginationItems = [];

    // Add 'Previous' link
    paginationItems.push(
      <li
        key='previous'
        className={`page-item${currentPage === 1 ? ' disabled' : ''}`}>
        <a
          className='page-link'
          href='#'
          tabIndex='-1'
          onClick={() => handlePageClick(currentPage - 1)}>
          Previous
        </a>
      </li>
    );

    // Add page links
    for (let i = 1; i <= data?.total_pages; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item${currentPage === i ? ' active' : ''}`}>
          <a className='page-link' href='#' onClick={() => handlePageClick(i)}>
            {i}
          </a>
        </li>
      );
    }

    // Add 'Next' link
    paginationItems.push(
      <li
        key='next'
        className={`page-item${
          currentPage === memberMembers?.total_pages ? ' disabled' : ''
        }`}>
        <a
          className='page-link'
          href='#'
          onClick={() => handlePageClick(currentPage + 1)}>
          Next
        </a>
      </li>
    );

    return paginationItems;
  };
  return (
    <section
      className={`${props.Fclass} section-padding  ${props.vclassClass}`}>
      <div className='container'>
        <div className='row'>
          {memberMembers.map(
            (member, sitem) =>
              member?.is_active && (
                <div
                  className='col col-xl-4 col-lg-6 col-sm-6 col-12  my-3'
                  key={sitem}
                  style={{
                    height: '600px',
                  }}>
                  <div
                    className=' shadow mb-5 bg-gray'
                    style={{
                      borderTopRightRadius: '30px',
                      borderBottomLeftRadius: '30px',
                    }}>
                    <div
                      className='text-center py-1 text-white mb-4 '
                      style={{
                        backgroundColor: '#192847',
                        borderTopRightRadius: '20px',
                        fontSize: '20px',
                        fontWeight: '700',
                        height: '45px',
                      }}>
                      {member?.agency_atab || ' '}
                    </div>
                    <div className='img-holder justify-content-center  d-flex position-relative'>
                      <img
                        src={
                          member?.image
                            ? `${BASE_URL}${member?.image}`
                            : '/userColor.png'
                        }
                        alt={member.first_name}
                        className='mb-3 text-center'
                        width='170'
                        height='170'
                        style={{
                          borderTopLeftRadius: '40px',
                          borderBottomRightRadius: '40px',
                          border: '3px solid #bfbdbd',
                        }}
                      />
                      <span
                        className='position-absolute text-center mx-auto text-white rounded-circle p-2'
                        style={{
                          fontSize: '18px',
                          bottom: '5px',
                          right: '120px',
                          width: '50px',
                          margin: '2px',
                          backgroundColor: '#192847',
                          border: '5px solid white',
                          display: 'flex',
                          textAlign: 'center',
                          justifyContent: 'center',
                        }}>
                        {member?.display_order || 0}
                      </span>
                    </div>

                    <div
                      className='p-4 pb-0 py-2 text-center'
                      style={{
                        height: '270px',
                      }}>
                      <h3 className='text-center'>
                        {member?.first_name} {member?.last_name}
                      </h3>
                      <h5 className='text-center' style={{ color: '#319336' }}>
                        {member?.profession || 'N/A'}
                      </h5>
                      <span>Address : {member?.street_address_one} </span>{' '}
                      <br />
                      <span className='mb-0'>
                        Contact : {member?.primary_phone},{' '}
                        {member?.others_phone}{' '}
                      </span>
                      <br />
                      <span>
                        Email: {member?.email} ,{member?.others_email}
                      </span>
                      <br />
                      {member?.website && (
                        <span>Website: {member?.website}</span>
                      )}
                    </div>
                    <div
                      className='text-center py-1 mt-2 text-white mb-4 '
                      style={{
                        backgroundColor: '#319336',
                        borderBottomLeftRadius: '20px',
                        fontSize: '20px',
                        fontWeight: '700',
                        height: '45px',
                      }}>
                      Membership No-
                      <span style={{ height: '45px' }}>
                        {(member?.membership_no || '')
                          .toString()
                          .padStart(3, '0')}
                      </span>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      <div className='px-5'>
        <ul className='pagination'>{renderPagination()}</ul>
      </div>
    </section>
  );
};

export default Member;
