import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Service5 from "../../components/Service5";
import { useDispatch, useSelector } from "react-redux";
import { getAllmenus } from "../../dataSlice/dataSlice";

const ServicePage = () => {
  const [homeId, setHomeId] = useState();
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.data.menus);
  useEffect(() => {
    dispatch(getAllmenus);
    menus.find((e) => (e.name === "Home" ? setHomeId(e.id) : null));
  }, [dispatch, menus, homeId]);
  console.log("nnnnnnn", homeId);
  return (
    <Fragment>
      <PageTitle pageTitle={"Our Services"} pagesub={"Service"} />
      <Service5 />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePage;
