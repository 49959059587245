import React, { Fragment, useEffect, useState } from 'react';
import PageTitle from '../../components/pagetitle';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import 'react-toastify/dist/ReactToastify.css';
import { CREATE_MEMBER, GET_MENUS_ALL_NESTED } from '../../constant/constants';
import Swal from 'sweetalert2';

const MembershipPage = () => {
  const [data, setData] = useState({});
  const [image, setImage] = useState({});
  const [allMemberMenuId, setAllMemberMenuId] = useState();
  const history = useHistory();
  console.log(`data`, data);
  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) =>
          e.name === 'All Member' ? setAllMemberMenuId(e.id) : null
        );
      })
      .catch(() => {});
  }, []);

  const updateData = (e) => {
    const { name, value, type, checked } = e.target;

    // Use conditional operator to set the correct value
    const updatedValue =
      name === 'primary_phone'
        ? '+88' + value
        : type === 'checkbox'
        ? checked
        : value;

    setData({
      ...data,
      [name]: updatedValue,
    });
  };

  // };
  const SubmitHandler = (e) => {
    //buildformdata
    const buildFormData = (formData, data, parentKey) => {
      if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data === null ? '' : data;

        formData.append(parentKey, value);
      }
    };

    //convertJsonToFormData
    const jsonToFormData = (data) => {
      const formData = new FormData();

      buildFormData(formData, data);
      return formData;
    };

    const memberData = jsonToFormData(data);
    memberData.append('image', image);
    e.preventDefault();
    fetch(`${CREATE_MEMBER}`, {
      method: 'POST',
      headers: {
        // 'Content-type': 'multipart/form-data',
        // authorization: `bearer ${localStorage.getItem("jwToken")}`,
      },
      body: memberData,
    })
      .then((res) => res.json())
      .then((result) => {
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: `Member Registered successfully,Please wait for Approval`,
          showConfirmButton: false,
          timer: 3000,
        });
        setTimeout(() => history.push(`/all member/${allMemberMenuId}`), 3000);
      });
  };

  return (
    <Fragment>
      <PageTitle pageTitle={'Membership Form'} />
      <div className='wpo-donation-page-area section-padding'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='bg-white text-center mt-3'>
                <img
                  src='/logo.png'
                  alt='logo'
                  className='mb-3 text-center bg-white'
                  width='100'
                  height='100'
                />
              </div>{' '}
              <div className='wpo-donate-header'>
                <h2>
                  Socheton Bebosayee Kalyan Somobay Somity Membership Form
                  <br />
                </h2>
              </div>
              <div id='Donations' className='tab-pane'>
                <form onSubmit={SubmitHandler}>
                  <div className='wpo-donations-details'>
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='first_name'
                          onChange={updateData}
                          id='first_name'
                          value={localStorage.getItem('first_name')}
                          placeholder='First Name'
                        />
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='last_name'
                          id='last_name'
                          onChange={updateData}
                          value={localStorage.getItem('last_name')}
                          placeholder='Last Name'
                        />
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix'>
                        <input
                          type='email'
                          className='form-control'
                          name='email'
                          id='email'
                          onChange={updateData}
                          value={localStorage.getItem('user_email')}
                          placeholder='Email'
                        />
                      </div>{' '}
                      <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix'>
                        <input
                          type='password'
                          className='form-control'
                          name='password'
                          id='password'
                          onChange={updateData}
                          placeholder='Password'
                        />
                      </div>
                      <div className='col-lg-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='others_email'
                          id='others_email'
                          onChange={updateData}
                          placeholder='Other Email'
                        />
                      </div>{' '}
                      <div className='col-lg-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='primary_phone'
                          id='primary_phone'
                          onChange={updateData}
                          placeholder='Phone Number'
                        />
                      </div>{' '}
                      <div className='col-lg-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='others_phone'
                          id='others_phone'
                          onChange={updateData}
                          placeholder='Others Number'
                        />
                      </div>
                      <div className='col-lg-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='profession'
                          id='profession'
                          onChange={updateData}
                          placeholder='Profession'
                        />
                      </div>
                      <div className='row'>
                        <div className='col-md-2 col-12 form-group'>
                          <div className='form-check'>
                            <label
                              className='form-check-label'
                              htmlFor='is_baira'>
                              Member Of
                            </label>
                          </div>
                        </div>
                        <div className='col-md-2 col-12 form-group'>
                          <div className='form-check'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              name='is_baira'
                              id='is_baira'
                              onChange={updateData}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='is_baira'>
                              BAIRA
                            </label>
                          </div>
                        </div>

                        <div className='col-md-2 col-12 form-group'>
                          <div className='form-check'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              name='is_atab'
                              id='is_atab'
                              onChange={updateData}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='is_atab'>
                              ATAB
                            </label>
                          </div>
                        </div>

                        <div className='col-md-2 col-12 form-group'>
                          <div className='form-check'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              name='is_toab'
                              id='is_toab'
                              onChange={updateData}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='is_toab'>
                              TOAB
                            </label>
                          </div>
                        </div>

                        <div className='col-md-2 col-12 form-group'>
                          <div className='form-check'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              name='is_haab'
                              id='is_haab'
                              onChange={updateData}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='is_haab'>
                              HAAB
                            </label>
                          </div>
                        </div>
                      </div>
                      {data?.is_baira && (
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                            <input
                              type='text'
                              className='form-control'
                              name='agency_baira'
                              onChange={updateData}
                              id='agency_baira'
                              value={localStorage.getItem('agency_baira')}
                              placeholder='Agency Name for BAIRA'
                            />
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                            <input
                              type='text'
                              className='form-control'
                              name='license_baira'
                              id='license_baira'
                              onChange={updateData}
                              value={localStorage.getItem('license_baira')}
                              placeholder='License No For BAIRA'
                            />
                          </div>
                        </div>
                      )}
                      {data?.is_toab && (
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                            <input
                              type='text'
                              className='form-control'
                              name='agency_toab'
                              onChange={updateData}
                              id='agency_toab'
                              value={localStorage.getItem('agency_toab')}
                              placeholder='Agency Name for TOAB'
                            />
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                            <input
                              type='text'
                              className='form-control'
                              name='license_toab'
                              id='license_toab'
                              onChange={updateData}
                              value={localStorage.getItem('license_toab')}
                              placeholder='License No For TOAB'
                            />
                          </div>
                        </div>
                      )}{' '}
                      {data?.is_atab && (
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                            <input
                              type='text'
                              className='form-control'
                              name='agency_atab'
                              onChange={updateData}
                              id='agency_atab'
                              value={localStorage.getItem('agency_atab')}
                              placeholder='Agency Name for ATAB'
                            />
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                            <input
                              type='text'
                              className='form-control'
                              name='license_atab'
                              id='license_atab'
                              onChange={updateData}
                              value={localStorage.getItem('license_atab')}
                              placeholder='License No For ATAB'
                            />
                          </div>
                        </div>
                      )}{' '}
                      {data?.is_haab && (
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                            <input
                              type='text'
                              className='form-control'
                              name='agency_haab'
                              onChange={updateData}
                              id='agency_haab'
                              value={localStorage.getItem('agency_haab')}
                              placeholder='Agency Name for HAAB'
                            />
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                            <input
                              type='text'
                              className='form-control'
                              name='license_haab'
                              id='license_haab'
                              onChange={updateData}
                              value={localStorage.getItem('license_haab')}
                              placeholder='License No For HAAB'
                            />
                          </div>
                        </div>
                      )}
                      <div className='col-lg-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='father_name'
                          id='father_name'
                          onChange={updateData}
                          placeholder='Father Name'
                        />
                      </div>
                      <div className='col-lg-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='mother_name'
                          id='mother_name'
                          onChange={updateData}
                          placeholder='Mother Name'
                        />
                      </div>
                      <div className='col-lg-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='street_address_one'
                          id='street_address_one'
                          onChange={updateData}
                          value={localStorage.getItem('street_address_one')}
                          placeholder='Address'
                        />
                      </div>{' '}
                      <div className='form-group row my-2'>
                        <label className='col-sm-2 col-form-label text-nowrap'>
                          Image
                        </label>
                        <div class='col-sm-10'>
                          {' '}
                          <input
                            type='file'
                            className='form-control'
                            onChange={(e) => setImage(e.target.files[0])}
                          />{' '}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='submit-area'>
                    <button type='submit' className='theme-btn submit-btn'>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default MembershipPage;
