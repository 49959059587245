import React, { Fragment, useEffect, useState } from 'react';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { GET_MENUS_ALL_NESTED } from '../../constant/constants';
import Form from '../../components/Form';

const FormPage = () => {
  const [profileId, setProfileId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === 'Form' ? setProfileId(e.id) : null));
      })
      .catch(() => {});
  }, []);
  console.log('Form', profileId);

  return (
    <Fragment>
      {' '}
      <PageTitle pageTitle={'Member Form'} pagesub={'Member Form'} />
      <Form id={profileId} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default FormPage;
