import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { BASE_URL, GET_ADVISERS_WITHOUT_PG } from '../../constant/constants';

var settings = {
  dots: false,
  arrows: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
const AdvisorMemberSection = (props) => {
  const [adviserMembers, setAdviserMembers] = useState([]);

  //for content images
  useEffect(() => {
    fetch(`${GET_ADVISERS_WITHOUT_PG}`)
      .then((response) => response.json())
      .then((data) => {
        setAdviserMembers(data.adviser_members);
        console.log('adviser_members', data.adviser_members);
      })
      .catch(() => {});
  }, []);

  console.log(`adviserMembers`, adviserMembers);
  return (
    <div
      className='wpo-team-area section-padding pt-1'
      style={{ backgroundColor: '#e9fafa' }}>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='wpo-section-title mb-3'>
              <span>Meet Our</span>
              <h2 className='my-0'>Advisor Member</h2>
            </div>
          </div>
        </div>
        <div className='wpo-team-wrap'>
          <div className='team-slider'>
            <Slider {...settings}>
              {adviserMembers.map((adviserMember, tm) => (
                <div
                  className='col col-xl-4 col-lg-6 col-sm-6 col-12  px-3'
                  key={tm}
                  style={{
                    height: '150px',
                    backgroundColor: 'transparent',
                  }}>
                  <div
                    className=' shadow mb-5 bg-gray pt-3'
                    style={{
                      borderTopRightRadius: '30px',
                      borderTopLeftRadius: '30px',
                      border: '2px solid #e6e0e0',
                    }}>
                    <div className='img-holder justify-content-center  d-flex position-relative'>
                      <img
                        src={
                          adviserMember?.image
                            ? `${BASE_URL}${adviserMember?.image}`
                            : '/userColor.png'
                        }
                        alt={adviserMember.name}
                        className='mb-3 text-center'
                        width='170'
                        height='170'
                        style={{
                          borderTopLeftRadius: '40px',
                          borderBottomRightRadius: '40px',
                          border: '3px solid #bfbdbd',
                        }}
                      />
                    </div>

                    <div
                      className='p-4 pb-0 py-2 text-center'
                      style={{
                        height: '100px',
                      }}>
                      <h5 className='text-center'>{adviserMember?.name}</h5>
                    </div>
                    <div
                      className='text-center py-1 mt-2 text-white  '
                      style={{
                        backgroundColor: '#319336',
                        fontSize: '16px',
                        fontWeight: '700',
                        height: '45px',
                      }}>
                      {adviserMember?.designation}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisorMemberSection;
