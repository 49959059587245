import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import BlogList from '../../components/BlogList';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import Logo from '../../images/logo.png';

const BlogPage = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <BlogList />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPage;
