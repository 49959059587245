import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import ExecutiveCommittee from '../../components/ExecutiveCommittee';

const AtabPage = () => {
  return (
    <Fragment>
      <PageTitle pageTitle={'Committee of ATAB'} />
      <ExecutiveCommittee association='ATAB' />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AtabPage;
