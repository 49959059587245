import React, { Fragment, useEffect, useState } from 'react';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import 'react-toastify/dist/ReactToastify.css';
import {
  BASE_URL,
  GET_EXECUTIVE_MEMBER_BY_SESSIONS,
} from '../../constant/constants';

const EcMemberPage = () => {
  const [executiveCommittees, setExecutiveCommittees] = useState([]);

  console.log('executiveCommittees', executiveCommittees);

  useEffect(() => {
    fetch(`${GET_EXECUTIVE_MEMBER_BY_SESSIONS}?key=&association=EC`)
      .then((response) => response.json())
      .then((data) => {
        setExecutiveCommittees(data?.executive_members);
      })
      .catch(() => {});
  }, []);

  return (
    <Fragment>
      <PageTitle pageTitle={'Our EC Member'} />
      <section>
        <div className='container my-5'>
          <div className='row'>
            {executiveCommittees?.map((member, sitem) => (
              <div
                className='col col-xl-4 col-lg-6 col-sm-6 col-12  my-3 '
                key={sitem}
                style={{
                  height: '450px',
                }}>
                <div
                  className=' shadow mb-5 bg-gray rounded '
                  style={{ border: '2px solid #d7d7d7' }}>
                  <div className='img-holder justify-content-center  d-flex position-relative pt-5'>
                    <img
                      src={
                        member?.member?.image
                          ? `${BASE_URL}${member?.member?.image}`
                          : '/userColor.png'
                      }
                      alt={member.first_name}
                      className='mb-3 text-center'
                      width='200'
                      height='200'
                      style={{
                        borderRadius: '100px',
                        border: '6px solid purple',
                      }}
                    />
                  </div>

                  <div
                    className='py-4 pb-0 py-2 text-center'
                    style={{
                      height: '200px',
                    }}>
                    <h3
                      className='text-center'
                      style={{
                        height: '50px',
                      }}>
                      {member?.member?.first_name} {member?.member?.last_name}
                    </h3>
                    <h5
                      className='text-center p-1'
                      style={{ color: '#319336' }}>
                      {member?.member?.profession || 'N/A'}
                    </h5>
                    <h4> {member?.member?.agency_baira || ''}</h4>

                    <div
                      className='text-center py-1 mt-2 my-auto text-white mb-4 '
                      style={{
                        backgroundColor: '#319336',

                        fontSize: '20px',
                        fontWeight: '700',
                        height: '46px',
                      }}>
                      <h4 className='text-white'>
                        {' '}
                        {member?.designation?.name || ''}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default EcMemberPage;
