/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import CauseTabs from "./alltab";
import CauseSidebar from "./sidebar";
import Footer from "../../components/footer";
import { useParams } from "react-router-dom";
import { BASE_URL, GET_CAUSE_BY_ID } from "../../constant/constants";

const CauseSinglePage = (props) => {
  const { id } = useParams();
  console.log("CauseSinglePageID", id);

  const [causes, setCauses] = useState();

  //for content images
  useEffect(() => {
    fetch(`${GET_CAUSE_BY_ID}${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setCauses(data);
      })
      .catch(() => {});
  }, [id, props.id]);
  console.log(`causes`, causes);
  return (
    <Fragment>
      <PageTitle pageTitle={causes?.name} />
      <div className="wpo-case-details-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-8">
              <div className="wpo-case-details-wrap">
                <div className="wpo-case-details-img">
                  <img src={`${BASE_URL}${causes?.image}`} alt="" />
                </div>
                <CauseTabs />
              </div>
            </div>
            {/* <CauseSidebar /> */}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <Scrollbar />
    </Fragment>
  );
};
export default CauseSinglePage;
