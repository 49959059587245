import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BASE_URL, GET_EXECUTIVES_WITHOUT_PG } from '../../constant/constants';

const Executive = (props) => {
  const [executiveMembers, setExecutiveMembers] = useState([]);

  const { id } = useParams();
  console.log('projecID', id);

  //for content images
  useEffect(() => {
    fetch(`${GET_EXECUTIVES_WITHOUT_PG}`)
      .then((response) => response.json())
      .then((data) => {
        setExecutiveMembers(data.executive_members);
        console.log('executive_members', data.executive_members);
      })
      .catch(() => {});
  }, [id, props.id]);

  return (
    <section
      className={`${props.Fclass} section-padding  ${props.vclassClass}`}>
      <div className='container'>
        <div className='row'>
          {executiveMembers.map((executiveMember, sitem) => (
            <div
              className='col col-xl-4 col-lg-6 col-sm-6 col-12  '
              key={sitem}>
              <div
                className='widget about-widget shadow p-3 mb-5 bg-gray   p-4'
                style={{ borderRadius: '50px' }}>
                <div className='img-holder justify-content-center d-flex'>
                  <img
                    src={`${BASE_URL}${executiveMember?.member?.image}`}
                    alt={executiveMember.first_name}
                    className=' rounded-circle mb-3 text-center shadow-lg p-1 mb-5 bg-white rounded'
                    width='200'
                    height='200'
                  />
                </div>
                <h2 className='text-center'>
                  {' '}
                  {executiveMember?.member?.first_name}{' '}
                  {executiveMember?.member?.last_name}
                </h2>
                <h5 className='text-center'>
                  {' '}
                  {executiveMember?.designation?.name}
                </h5>
                <p>Contact : {executiveMember?.member?.primary_phone} </p>
                <p>Profession : {executiveMember?.member?.profession} </p>
                <p>Address : {executiveMember?.member?.street_address_one} </p>
                <div className='social'></div>
                <div className='aw-shape'></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Executive;
