import React from 'react';

const PageTitle = (props) => {
  return (
    <div
      className='wpo-breadcumb-area'
      style={{ backgroundImage: 'url("/background.jpg")' }}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 '>
            <div className='wpo-breadcumb-wrap'>
              <h2>{props.pageTitle}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
