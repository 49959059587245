/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  BASE_URL,
  GET_EXECUTIVE_MEMBER_BY_SESSIONS,
  GET_EXECUTIVE_MEMBER_SESSIONS,
} from '../../constant/constants';

const ExecutiveCommittee = (props) => {
  const { id } = useParams();
  const [executiveCommittees, setExecutiveCommittees] = useState({});

  console.log('executiveCommittees', executiveCommittees);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [data, setData] = useState();

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    fetch(
      `${GET_EXECUTIVE_MEMBER_BY_SESSIONS}?key=${data?.session}&association=${props?.association}`
    )
      .then((response) => response.json())
      .then((data) => {
        setExecutiveCommittees(data);
      })
      .catch(() => {});
  }, [data?.session]);
  useEffect(() => {
    fetch(`${GET_EXECUTIVE_MEMBER_SESSIONS}`)
      .then((response) => response.json())
      .then((data) => {
        setSelectedOptions(data);
      })
      .catch(() => {});
  }, [id]);
  return (
    <section className='wpo-about-text' style={{ marginLeft: '45px' }}>
      <div className='row mb-4 px-4 mt-3'>
        <div className='col-md-2  ' style={{ textAlign: 'right' }}>
          <h5>Session :</h5>
        </div>

        <div className='col-md-4 col-md-4 col-4 mb-4 form  '>
          <div className='form-field rounded'>
            <select
              className='form-control'
              onChange={updateData}
              type='text'
              name='session'
              id='session'
              value={executiveCommittees?.session || 'without_photo'}
              style={{ borderRadius: '20px' }}>
              <option value='without_photo'>Select Session</option>
              {selectedOptions?.map((selectedOption, index) => (
                <option key={index} value={selectedOption}>
                  {selectedOption}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div>
        {executiveCommittees?.executive_members?.length > 0 ? (
          <div>
            <div className=' mb-2 text-center'>
              <h4>
                {props?.association} Executive Committee in &nbsp;
                {executiveCommittees?.session}
                &nbsp; Session
              </h4>
            </div>
            <div className='row container mx-auto'>
              {executiveCommittees?.executive_members?.map(
                (executiveCommittee, index) => (
                  <div className='col-md-6 p-3 px-5 ' key={index}>
                    <div
                      className='row shadow'
                      style={{
                        backgroundColor: '#e6f1e0',
                        borderTopLeftRadius: '30px',
                        borderBottomRightRadius: '30px',
                        border: '2px solid #d7d7d7',
                      }}>
                      <div className='col-md-3 my-auto '>
                        <img
                          src={`${BASE_URL}${executiveCommittee?.member?.image}`}
                          className='rounded-left  border border-dark'
                          width='100'
                          style={{
                            borderTopRightRadius: '30px',
                            borderBottomLeftRadius: '30px',
                          }}
                          loading='lazy'
                          height='100'
                          alt='...'
                        />
                      </div>
                      <div
                        className='col-md-9 mt-3 '
                        style={{ paddingRight: '0px' }}>
                        <div
                          style={{
                            backgroundColor: '#172847',
                            borderLeft: '3px solid white',
                            borderTop: '3px solid white',
                            borderBottom: '3px solid white',
                          }}>
                          <h4 className='text-white p-1'>
                            {executiveCommittee?.member?.first_name} &nbsp;
                            {executiveCommittee?.member?.last_name}
                          </h4>
                          <h5
                            className='pb-1 px-2'
                            style={{ color: '#2d9436' }}>
                            {executiveCommittee?.designation?.name}
                          </h5>
                        </div>
                        <small> {executiveCommittee?.member?.profession}</small>
                        <h4> {executiveCommittee?.member?.agency_name}</h4>
                        {props?.association === 'BAIRA' ? (
                          <h5>
                            <b>{executiveCommittee?.member?.agency_baira}</b>
                            <br />
                          </h5>
                        ) : props?.association === 'HAAB' ? (
                          <h5>
                            <b> {executiveCommittee?.member?.agency_haab}</b>
                            <br />
                          </h5>
                        ) : props?.association === 'TOAB' ? (
                          <h5>
                            <b> {executiveCommittee?.member?.agency_toab}</b>
                            <br />
                          </h5>
                        ) : props?.association === 'ATAB' ? (
                          <h5>
                            <b>{executiveCommittee?.member?.agency_atab}</b>
                            <br />
                          </h5>
                        ) : (
                          ''
                        )}
                        {props?.association === 'BAIRA' ? (
                          <small>
                            <b>
                              RL- {executiveCommittee?.member?.license_baira}
                            </b>
                            <br />
                          </small>
                        ) : props?.association === 'HAAB' ? (
                          <small>
                            <b>
                              HL- {executiveCommittee?.member?.license_haab}
                            </b>
                            <br />
                          </small>
                        ) : (
                          ''
                        )}

                        <small>
                          {executiveCommittee?.member?.street_address_one}
                        </small>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <div>
            <h2 className='text-center'>
              No Committee of {props?.association} found in
              {executiveCommittees?.session} session
            </h2>
          </div>
        )}
      </div>
    </section>
  );
};

export default ExecutiveCommittee;
