/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BASE_URL,
  CHECK_PRIMARY_PHONE,
  CREATE_MEMBER,
  GET_CITYS_WITHOUT_PAGINATION,
  GET_CONTENTS_BY_MENU_ID,
  GET_COUNTRIES_WITHOUT_PAGINATION,
  GET_THANAS_WITHOUT_PAGINATION,
  THANAS_BASED_CITY,
} from '../../constant/constants';

import { useForm } from 'react-hook-form';
// import { toast } from "react-hot-toast";

// import { FormProvider, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { Controller, useFormContext } from "react-hook-form";
// import { TextField } from "@material-ui/core";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const Form = (props) => {
  // const methods = useFormContext();

  // const { control, watch, setValue, formState, setError, reset, getValues } =
  //   methods;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [contents, setContents] = useState('');

  const [contentsImage, setContentsImage] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [thanas, setThanas] = useState([]);
  const [memberImage, setMemberImage] = useState();
  const [loading, setLoading] = useState(false);

  const genders = [
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' },
    { id: 'others', name: 'Others' },
  ];

  const { id } = useParams();
  console.log('projecID', id);
  console.log('contents', contents);

  //for content images
  useEffect(() => {
    fetch(`${GET_CITYS_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setCities(data.cities);
        // console.log("imagesContent", data.content_images);
      })
      .catch(() => {});

    fetch(`${GET_COUNTRIES_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        // console.log("imagesContent", data.content_images);
      })
      .catch(() => {});

    fetch(`${GET_THANAS_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setThanas(data.thanas);
        // console.log("imagesContent", data.content_images);
      })
      .catch(() => {});
  }, [id, props.id]);
  const images = Object.entries(contentsImage ? contentsImage : {}).map(
    ([key, value]) =>
      key === 'Form' ? value.map((n) => `${BASE_URL}/media/${n}`) : ' '
  );
  console.log('FormImage', images);

  //for content
  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log('allmenucontent', data.menu_contents);
      })
      .catch(() => {});
  }, [id, props.id]);

  const getThanaBasedCity = () => {
    const cityId = document.getElementById('city').value;
    fetch(`${THANAS_BASED_CITY}${cityId}`)
      .then((response) => response.json())
      .then((data) => {
        setThanas(data.thanas || []);
        // console.log("imagesContent", data.content_images);
      })
      .catch(() => {});
  };

  //for content items
  const newContentsImage = items(contentsImage);
  console.log('newContentsImage', newContentsImage);

  const handleAddMember = (data) => {
    // toast.success("Member added successfully");
    console.log(`memberData`, data);

    const image = data.image[0];
    const formData = new FormData();

    formData.append('image', image);

    const member = {
      first_name: data.first_name,
      last_name: data.last_name,
      father_name: data.father_name,
      mother_name: data.mother_name,
      spouse_name: data.spouse_name,
      username: data.username,
      street_address_one: data.street_address_one,
      street_address_two: data.street_address_two,
      email: data.email,
      profession: data.profession,
      password: data.password,
      confirmPassword: data.password,
      primary_phone: `+88${data.primary_phone}`,

      gender: data.gender,
      thana: data.thana,
      city: data.city,
      // image: image,
      country: data.country,
      postal_code: data.postal_code,
      nid: data.nid,
      role: 0,
      user_type: data.user_type,
      is_active: true,
      is_admin: false,
      policeStation: data.policeStation,
      district: data.district,
      active: true,
    };

    Object.keys(member).forEach((key) => {
      formData.append(key, member[key]);
    });
    console.log(`memberFormData`, formData);
    const notify = () =>
      toast.success(
        'You are successfully added in our Member List, Please Contact with admin.',
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    setLoading(true);

    //  saving products to mongodb by post method
    fetch(`${CREATE_MEMBER}`, {
      method: 'POST',
      headers: {
        // "content-type": "multipart/form-data",
        // authorization: `bearer ${localStorage.getItem("jwToken")}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        notify();
      });
    document.getElementById('memberForm').reset();
  };

  const duplicateNumberCheck = () => {
    const primaryPhn = document.getElementById('primary_phone').value;
    fetch(`${CHECK_PRIMARY_PHONE}?primary_phone=+88${primaryPhn}`)
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        if (data.primary_phone_exists) {
          alert('Phone Number Already Exists');
          const getValue = document.getElementById('primary_phone');
          getValue.value = '';
        }
      });
  };

  return (
    <>
      <div
        className=' w-50 h-50'
        style={{ position: 'fixed', top: '25%', left: '43%', zIndex: 100 }}>
        <img
          width='200'
          src='/loader.svg'
          alt='logo'
          className='text-center'
          style={{ display: loading ? 'block' : 'none' }}
        />
      </div>
      <div className='wpo-section-title'>
        <h2>Get Our Membership</h2>
      </div>
      <section
      // className={`${props.Fclass} section-padding  ${props.vclassClass}`}
      >
        <div className='col-md-9 mx-auto  shadow-lg p-3 mb-5 bg-white rounded'>
          <form onSubmit={handleSubmit(handleAddMember)} id='memberForm'>
            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                First Name<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('first_name', {
                    required: 'This Field is required',
                  })}
                  className='  form-control'
                />
              </div>
            </div>

            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Last Name<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('last_name', {
                    required: 'This Field is required',
                  })}
                  className='  form-control'
                />
              </div>
            </div>

            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Father Name<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('father_name', {
                    required: 'This Field is required',
                  })}
                  className='  form-control'
                />
              </div>
            </div>
            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Profession<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('profession', {
                    required: 'This Field is required',
                  })}
                  className='  form-control'
                />
              </div>
            </div>

            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Permanent Address<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('street_address_one', {
                    required: 'This Field is required',
                  })}
                  className='  form-control'
                />
              </div>
            </div>

            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Mobile Number<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  maxlength='11'
                  id='primary_phone'
                  {...register('primary_phone', {
                    required: 'Phone Number is required',
                  })}
                  onChange={() => duplicateNumberCheck()}
                  placeholder='0196*******'
                  className='form-control'
                />
              </div>
            </div>

            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Email<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='email'
                  {...register('email', {
                    required: 'Email is required',
                  })}
                  className='  form-control'
                />
              </div>
            </div>
            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Member Type<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                {' '}
                <label htmlFor='user_type'>
                  <input
                    {...register('user_type', { required: true })}
                    type='radio'
                    name='user_type'
                    value='general_member'
                    className='form-check-input'
                    id='user_type'
                  />{' '}
                  General Member
                </label>
                <label htmlFor='burger'>
                  <input
                    {...register('user_type', { required: true })}
                    type='radio'
                    name='user_type'
                    value='life_member'
                    className='form-check-input ms-2'
                    id='user_type'
                  />{' '}
                  Life Member
                </label>
              </div>
            </div>

            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Gender<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                <select
                  {...register('gender', {
                    required: 'This Field is required',
                  })}
                  className='form-control'>
                  {genders.map((gender) => (
                    <option key={gender.id} value={gender.id}>
                      {gender.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Country<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                <select
                  {...register('country', {
                    required: 'This Field is required',
                  })}
                  className='form-control'>
                  <option>Please Select Country</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                District<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                <select
                  {...register('city', { required: 'This Field is required' })}
                  className='form-control'
                  id='city'
                  onChange={() => getThanaBasedCity()}>
                  <option>Please Select City</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Police Station<span className='text-danger'>*</span>
              </label>
              <div class='col-sm-10'>
                <select
                  {...register('thana', { required: 'This Field is required' })}
                  className='form-control'>
                  <option>Please Select Thana</option>
                  {thanas.map((thana) => (
                    <option key={thana.id} value={thana.id}>
                      {thana.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Mother Name
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('mother_name', {})}
                  className='  form-control'
                />
              </div>
            </div>
            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Spouse Name
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('spouse_name', {})}
                  className='  form-control'
                />
              </div>
            </div>
            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                User Name
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('username', {})}
                  className='  form-control'
                />
              </div>
            </div>

            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Present Address
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('street_address_two', {})}
                  className='  form-control'
                />
              </div>
            </div>

            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Password
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='password'
                  {...register('password', {})}
                  className='  form-control'
                />
              </div>
            </div>

            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Post Code
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('postal_code', {
                    // required: "User Name is required",
                  })}
                  className='  form-control'
                />
              </div>
            </div>
            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>NID</label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='text'
                  {...register('nid', {
                    // required: "User Name is required",
                  })}
                  className='  form-control'
                />
              </div>
            </div>
            <div className='form-group row my-2'>
              <label className='col-sm-2 col-form-label text-nowrap'>
                Image
              </label>
              <div class='col-sm-10'>
                {' '}
                <input
                  type='file'
                  {...register('image', {})}
                  className='  form-control'
                />
              </div>
            </div>

            <input
              className='btn btn-info  mt-4 px-5 py-1 mb-5'
              value='Save'
              type='submit'
            />
          </form>
        </div>
      </section>
      <div className='my-5'>
        <ToastContainer />
      </div>
    </>
  );
};

export default Form;
