import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import LiveProgramSection from '../../components/LiveProgramSection';

const LiveProgramPage = () => {
  return (
    <Fragment>
      <PageTitle pageTitle={'Live Project'} />

      <LiveProgramSection pbClass={'pb-0'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default LiveProgramPage;
