import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';

import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
} from '../../constant/constants';
function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const Gallery = (props) => {
  const [contents, setContents] = useState('');
  const [contentsImage, setContentsImage] = useState([]);

  const { id } = useParams();
  console.log('projecID', id);
  console.log('contents', contents);

  //for content images
  useEffect(() => {
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
        // console.log("imagesContent", data.content_images);
      })
      .catch(() => {});
  }, [id, props.id]);

  const images = Object.entries(contentsImage).map(([key, value]) =>
    key === 'Gallery' ? value.map((n) => `${BASE_URL}/media/${n}`) : ' '
  );
  console.log('GalleryImage', images);

  //for content
  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log('allmenucontent', data.menu_contents);
      })
      .catch(() => {});
  }, [id, props.id]);

  //for content items
  const newContentsImage = items(contentsImage);
  console.log('newContentsImage', newContentsImage);

  // for image viewer
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    console.log('indexImage', index);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  console.log('currentImage', currentImage);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <div className='row container mx-auto mt-4'>
        {images.map((item, index) =>
          item.map((item, index) => (
            <div className='col-md-4'>
              <img
                // style={{ height: "100%", width: "95%" }}
                src={`${item}`}
                srcSet={`${item}`}
                alt=''
                height='250px'
                width='350px'
                className='rounded shadow-lg p-1 mb-5 bg-light rounded'
                onClick={() => openImageViewer(index)}
                loading='lazy'
              />
            </div>
          ))
        )}
      </div>

      {isViewerOpen &&
        images.map((n) => (
          <ImageViewer
            src={n}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        ))}
    </>
  );
};

export default Gallery;
