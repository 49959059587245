import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import PreviousProgramSection from '../../components/PreviousProgramSection';

const PreviousProgramPage = () => {
  return (
    <Fragment>
      <PageTitle pageTitle={'Previous Project'} />

      <PreviousProgramSection pbClass={'pb-0'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PreviousProgramPage;
