/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Projects from "../../api/projects";
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
} from "../../constant/constants";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const ProjectSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [contents, setContents] = useState("");
  const [advisorMembers, setAdvisorMembers] = useState([]);
  const [contentsImage, setContentsImage] = useState([]);

  const { id } = useParams();
  console.log("projecID", id);
  console.log("contents", contents);

  //for content images
  useEffect(() => {
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
        // console.log("imagesContent", data.content_images);
      })
      .catch(() => {});
  }, [id, props.id]);
  const images = Object.entries(contentsImage ? contentsImage : {}).map(
    ([key, value]) =>
      key === "Project" ? value.map((n) => `${BASE_URL}/media/${n}`) : " "
  );
  console.log("ProjectImage", images);

  //for content
  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log("allmenucontent", data.menu_contents);
      })
      .catch(() => {});
  }, [id, props.id]);

  //for content items
  const newContents = items(contents);
  const newContentsImage = items(contentsImage);
  console.log("newContents", newContents, contentsImage, Projects);

  const modifiedProjects = [];
  newContents.map((project, pitem) => {
    console.log(`project, pitem`, project, pitem);
    Object.entries(project).forEach(([key, value]) => {
      modifiedProjects[pitem] = {
        title: key,
        dec: value,
      };
    });
  });

  console.log(`modifiedProjects`, modifiedProjects);
  return (
    <section className={`wpo-project-section section-padding ${props.pbClass}`}>
      <div className="container-fluid">
        <div className="sortable-gallery">
          <div className="row">
            <div className="col-lg-12">
              <div className="project-grids gallery-container clearfix">
                {Projects.map((project, pitem) => (
                  <div className="grid" key={pitem}>
                    <div className="img-holder">
                      <img src={project.projectImg} alt="" />
                      <div className="hover-content">
                        <Link
                          onClick={ClickHandler}
                          className="plus"
                          to={`/project-single/${project.id}`}
                        >
                          <i className="ti-plus"></i>
                        </Link>
                        <h4>
                          <Link
                            onClick={ClickHandler}
                            to={`/project-single/${project.id}`}
                          >
                            {project.title}
                          </Link>
                        </h4>
                        <p>
                          {" "}
                          <Interweave
                            allowAttributes
                            allowElements
                            disableLineBreaks={true}
                            content={project.dec}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;

//  <div className="grid" key={pitem}>
//    <div className="img-holder">
//      <img src={project.projectImg} alt="" />
//      <div className="hover-content">
//        <Link
//          onClick={ClickHandler}
//          className="plus"
//          to={`/project-single/${project.id}`}
//        >
//          <i className="ti-plus"></i>
//        </Link>
//        <h4>
//          <Link onClick={ClickHandler} to={`/project-single/${project.id}`}>
//            {project.title}
//          </Link>
//        </h4>
//        <p>
//          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//          tempor incididunt ut labore et dolore magna aliqua.
//        </p>
//      </div>
//    </div>
//  </div>
